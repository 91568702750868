/* eslint-disable react/prop-types */
import { graphql } from 'gatsby';
import Layout from 'templates/Layout';
import InPartnershipWith from 'modules/InPartnershipWith';
import NewsletterSlim from 'modules/NewsletterSlim';
import FactBoxes from 'modules/FactBoxes';
import StartupDataBubbles from 'modules/StartupDataBubbles';
import EcospheresNav from 'components/EcospheresNav';
import EcosphereClusterList from 'modules/EcosphereClusterList';
import Text from 'modules/Text';
import TextAndImage from 'modules/TextAndImage';
import TextAndVideo from 'modules/TextAndVideo';
import HeroWithIllustration from 'modules/HeroWithIllustration';
import { contentStyles, Half, Inner, PageTitle, Section } from 'components';
import tw, { styled } from 'twin.macro';
import { motion } from 'framer-motion';
import { preventWidow } from 'utils/';
import LargeVideo from 'modules/LargeVideo';
import AnimatedNumber from 'components/AnimatedNumber';
import ThreeColumnCTA from 'modules/ThreeColumnCTA';

export const ecosystemsQuery = graphql`
  query MyQuery {
    allDatoCmsDanishStartupsInNumbersPage(sort: { fields: position }) {
      edges {
        node {
          slug
          title
        }
      }
    }
    datoCmsDanishStartupsInNumber {
      seo {
        title
        description
        image {
          url
        }
      }
      headline
      heroBodyNode {
        childMarkdownRemark {
          html
        }
      }
      amountOfStartups
      amountOfEmployees
      blocks {
        ... on DatoCmsTextImage {
          model {
            apiKey
          }
          heading
          bodyTextNode {
            childMarkdownRemark {
              html
            }
          }
          image {
            gatsbyImageData(placeholder: BLURRED, width: 640)
          }
          reverseOrder
        }
        ... on DatoCmsLargeVideo {
          model {
            apiKey
          }
          videoEmbedUrl
        }
        ... on DatoCmsTextVideo {
          model {
            apiKey
          }
          heading
          bodyTextNode {
            childMarkdownRemark {
              html
            }
          }
          videoEmbedUrl
          reverseOrder
        }
        ... on DatoCmsTextStartupBubble {
          model {
            apiKey
          }
          heading
          bodyTextNode {
            childMarkdownRemark {
              html
            }
          }
        }
        ... on DatoCmsFactBox {
          model {
            apiKey
          }
          heading
          facts {
            heading
            bodyText
          }
          bigFont15Degrees
        }
        ... on DatoCmsNewsletterSlim {
          model {
            apiKey
          }
          mailchimpSignUpFrom
          callToActionLabel
          heading
        }
        ... on DatoCmsInPartnershipWith {
          model {
            apiKey
          }
          heading
        }
        ... on DatoCmsEcosphereClustersList {
          model {
            apiKey
          }
          heading
          ecospheres {
            ecoid
            clusterWebsite
            clusterLogo {
              width
              height
              blurhash
              url
              gatsbyImageData(width: 400)
              alt
              title
            }
            clusterWhiteLogo {
              width
              height
              blurhash
              url
              gatsbyImageData(width: 400)
              alt
              title
            }
            icon {
              url
            }
          }
        }
        ... on DatoCmsThreeColumnCta {
          model {
            apiKey
          }
          heading
          headingColumn1
          bodyTextColumn1
          callToActionColumn1 {
            label
            href
          }
          headingColumn2
          bodyTextColumn2
          callToActionColumn2 {
            label
            href
          }
          headingColumn3
          bodyTextColumn3
          callToActionColumn3 {
            label
            href
          }
        }
      }
    }
  }
`;

const defaultAppear = {
  initial: {
    y: '100%',
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 1,
    },
  },
};

const NumberGradient = styled.div`
  ${tw`absolute inset-0 top-1/3 bg-gradient-to-t from-blue-dark pointer-events-none`}
`;

function DenmarkInNumbersPage({ data }) {
  const { datoCmsDanishStartupsInNumber, allDatoCmsDanishStartupsInNumbersPage } = data;
  const { headline, heroBodyNode, amountOfStartups, amountOfEmployees } =
    datoCmsDanishStartupsInNumber;

  const pages = allDatoCmsDanishStartupsInNumbersPage.edges.map((eco) => eco.node);

  const components = datoCmsDanishStartupsInNumber.blocks.map((block, i) => {
    if (block?.model) {
      const { apiKey } = block.model;
      const key = apiKey + i;

      switch (apiKey) {
        case 'hero_with_illustration':
          return <HeroWithIllustration {...block} key={key} />;
        case 'text_startup_bubble':
          return <StartupDataBubbles {...block} key={key} isHero={i === 0} />;
        case 'fact_box':
          return <FactBoxes {...block} key={key} />;
        case 'newsletter_slim':
          return <NewsletterSlim {...block} key={key} />;
        case 'in_partnership_with':
          return <InPartnershipWith {...block} key={key} />;
        case 'ecosphere_clusters_list':
          return <EcosphereClusterList {...block} key={key} />;
        case 'text':
          return <Text {...block} key={key} />;
        case 'text_image':
          return <TextAndImage {...block} key={key} />;
        case 'large_video':
          return <LargeVideo {...block} key={key} />;
        case 'text_video':
          return <TextAndVideo {...block} key={key} />;
        case 'three_column_cta':
          return <ThreeColumnCTA {...block} key={apiKey} />;
        default:
          break;
      }
    }
    return null;
  });

  return (
    <Layout seo={datoCmsDanishStartupsInNumber.seo}>
      <EcospheresNav
        pages={[
          {
            slug: 'all-startups',
            title: 'All',
          },
          ...pages,
          {
            slug: 'about',
            title: 'About',
          },
        ]}
      />

      <Section show>
        <Inner css={[tw`lg:flex items-center`]}>
          <div css={tw`lg:w-1/2`}>
            <Half tw="relative z-10 w-full!">
              <PageTitle>{preventWidow(headline)}</PageTitle>
              <div
                tw="mt-6"
                css={contentStyles}
                dangerouslySetInnerHTML={{ __html: heroBodyNode.childMarkdownRemark.html }}
              />
            </Half>
          </div>

          <motion.div
            css={tw`text-center lg:w-1/2 mt-12 lg:mt-0`}
            initial="initial"
            animate="animate"
            transition={{
              staggerChildren: 0.2,
            }}
          >
            {amountOfStartups && (
              <div>
                <div css={tw`overflow-hidden`}>
                  <motion.div
                    css={tw`relative text-6xl md:text-[6.75vw] xl:text-[6.25rem] font-bold text-green leading-none`}
                    variants={defaultAppear}
                  >
                    <AnimatedNumber
                      from={amountOfStartups - 100}
                      to={amountOfStartups}
                      duration={1}
                      format={(num) => num.toLocaleString('da-DK').replace('.', ' ')}
                    />

                    <NumberGradient />
                  </motion.div>
                </div>
                <div css={tw`text-xl opacity-50 overflow-hidden`}>
                  <motion.div variants={defaultAppear}>Digital tech companies</motion.div>
                </div>
              </div>
            )}

            {amountOfEmployees && (
              <div>
                <div css={tw`overflow-hidden`}>
                  <motion.div
                    css={tw`relative text-6xl md:text-[6.75vw] xl:text-[6.25rem] font-black text-green leading-none mt-12`}
                    variants={defaultAppear}
                  >
                    <AnimatedNumber
                      from={amountOfEmployees - 400}
                      to={amountOfEmployees}
                      duration={1}
                      format={(num) => num.toLocaleString('da-DK').replace('.', ' ')}
                    />

                    <NumberGradient />
                  </motion.div>
                </div>
                <div css={tw`text-xl opacity-50 overflow-hidden`}>
                  <motion.div variants={defaultAppear}>Employees</motion.div>
                </div>
              </div>
            )}
          </motion.div>
        </Inner>
      </Section>

      {components}
    </Layout>
  );
}

export default DenmarkInNumbersPage;
